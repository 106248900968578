<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:right-content="page.rightContent"
		/>

		<contentblocks
			v-if="page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:contentblocks-content="page.contentBlockContent"
		/>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletterForm.title"
			:content="defaults[locale].newsletterForm.content"
			:email="defaults[locale].newsletterForm.email"
			:button="defaults[locale].newsletterForm.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();
</script>
